import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage';
import AdminDashboard from './AdminDashboard';
import Advertisements from './Advertisements';
import PostPage from './PostPage';
import TamilHomepage from './TamilHomepage';
import EnglishHomepage from './EnglishHomepage';
import PostPageEnglish from './PostPageEnglish';
import PostPageTamil from './PostPageTamil';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute';
import './App.css'; // Import the CSS file
import RadioPlayer from './RadioPlayer';

import Admin from './Admin';
import Slider from './Slider';
import YoutubeVideos from './YoutubeVideos';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            } 
          />
          <Route path="/" element={<Homepage />} />
          <Route path="/tamil" element={<TamilHomepage />} />
          <Route path="/english" element={<EnglishHomepage />} />
          <Route path="/advertisements" element={<Advertisements />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/postenglish/:id" element={<PostPageEnglish />} />
          <Route path="/tamilpost/:id" element={<PostPageTamil />} />
          <Route path="/radio" element={<RadioPlayer />} />

          <Route path="/admin1" element={<Admin />} />
          <Route path="/slider" element={<Slider />} />
          <Route path="/video" element={<YoutubeVideos />} />
  
        </Routes>
      </div>
    </Router>
  );
}

export default App;
