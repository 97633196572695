import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import './RadioPlayer.css';

const RadioPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: 'Roboto', sans-serif;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .btn-text {
    background-color: #d32f2f;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #b71c1c;
    }
  }
`;

const VolumeControl = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .volume-slider {
    width: 200px;
    margin-left: 10px;
  }
`;

const Status = styled.div`
  font-size: 1.2em;
  color: ${props => (props.isOnline ? '#388e3c' : '#d32f2f')};
`;

const RadioPlayer = () => {
  const audioRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const audioElement = audioRef.current;

    const playStream = () => {
      setIsLoading(true);
      audioElement.src = "https://altair.streamerr.co/stream/8000";
      audioElement.load();
      audioElement.play().then(() => {
        setIsLoading(false);
      }).catch(error => {
        console.error('Error attempting to play the stream:', error);
        setIsLoading(true);
        setTimeout(playStream, 5000);
      });
    };

    const handleAudioError = () => {
      console.error('Stream error, attempting to reload the stream...');
      playStream();
    };

    const handleOnline = () => {
      console.log('Internet connection restored, refreshing the page...');
      setIsOnline(true);
      window.location.reload(true);
    };

    const handleOffline = () => {
      console.log('Internet connection lost.');
      setIsOnline(false);
    };

    playStream();

    audioElement.addEventListener('error', handleAudioError);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    audioElement.muted = false;
    setIsMuted(false);

    return () => {
      audioElement.removeEventListener('error', handleAudioError);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleLiveButtonClick = () => {
    const audioElement = audioRef.current;
    audioElement.src = "https://altair.streamerr.co/stream/8000";
    audioElement.load();
    audioElement.play().catch(error => {
      console.error('Error attempting to play the live stream:', error);
    });
  };

  const handleMuteButtonClick = () => {
    const audioElement = audioRef.current;
    audioElement.muted = !audioElement.muted;
    setIsMuted(audioElement.muted);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    const audioElement = audioRef.current;
    audioElement.volume = newVolume;
    setVolume(newVolume);
  };

  return (
    <RadioPlayerContainer>
      <Controls>
        <audio ref={audioRef} style={{ display: 'none' }}>
          <source src="https://altair.streamerr.co/stream/8000" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <button className="btn-text" onClick={handleLiveButtonClick}>Live</button>
        <button className="btn-text" onClick={handleMuteButtonClick}>
          {isMuted ? 'Unmute' : 'Mute'}
        </button>
      </Controls>
      <VolumeControl>
        Volume:
        <input
          type="range"
          className="volume-slider"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        />
      </VolumeControl>
      <Status isOnline={isOnline}>
        {isLoading ? 'Connecting...' : isOnline ? 'LIVE HELLO RADIO' : 'No connection.'}
      </Status>
    </RadioPlayerContainer>
  );
};

export default RadioPlayer;
