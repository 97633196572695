import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import HeaderAdmin from './HeaderAdmin';
import Footer from './Footer';
import { Modal, Button, Spinner } from 'react-bootstrap';

const socket = io('https://helloradio.lk');

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [videos, setVideos] = useState([]);
  const fileInputRef = useRef(null);

  const [newsFile, setNewsFile] = useState(null);
  const [newsHeading, setNewsHeading] = useState('');
  const [newsDescription, setNewsDescription] = useState('');
  const [newsVideoUrl, setNewsVideoUrl] = useState('');
  const [adFile, setAdFile] = useState(null);
  const [advertisements, setAdvertisements] = useState([]);
  const [news, setNews] = useState([]);
  const [isExtended, setIsExtended] = useState(false);

  const [newsFileEnglish, setNewsFileEnglish] = useState(null);
  const [newsHeadingEnglish, setNewsHeadingEnglish] = useState('');
  const [newsDescriptionEnglish, setNewsDescriptionEnglish] = useState('');
  const [newsVideoUrlEnglish, setNewsVideoUrlEnglish] = useState('');
  const [adFileEnglish, setAdFileEnglish] = useState(null);
  const [advertisementsEnglish, setAdvertisementsEnglish] = useState([]);
  const [newsEnglish, setNewsEnglish] = useState([]);
  const [isExtendedEnglish, setIsExtendedEnglish] = useState(false);

  const [newsFileTamil, setNewsFileTamil] = useState(null);
  const [newsHeadingTamil, setNewsHeadingTamil] = useState('');
  const [newsDescriptionTamil, setNewsDescriptionTamil] = useState('');
  const [newsVideoUrlTamil, setNewsVideoUrlTamil] = useState('');
  const [adFileTamil, setAdFileTamil] = useState(null);
  const [advertisementsTamil, setAdvertisementsTamil] = useState([]);
  const [newsTamil, setNewsTamil] = useState([]);
  const [isExtendedTamil, setIsExtendedTamil] = useState(false);

  const [editingPostSinhala, setEditingPostSinhala] = useState(null);
  const [showEditModalSinhala, setShowEditModalSinhala] = useState(false);
  const [showViewModalSinhala, setShowViewModalSinhala] = useState(false);

  const [editingPostEnglish, setEditingPostEnglish] = useState(null);
  const [showEditModalEnglish, setShowEditModalEnglish] = useState(false);
  const [showViewModalEnglish, setShowViewModalEnglish] = useState(false);

  const [editingPostTamil, setEditingPostTamil] = useState(null);
  const [showEditModalTamil, setShowEditModalTamil] = useState(false);
  const [showViewModalTamil, setShowViewModalTamil] = useState(false);

  const [loadingState, setLoadingState] = useState({
    uploadBanner: false,
    uploadVideo: false,
    uploadAdvertisement: false,
    uploadNewsSinhala: false,
    uploadNewsEnglish: false,
    uploadNewsTamil: false,
    deleteImage: null,
    deleteVideo: null,
    deleteAd: null,
    deleteNewsSinhala: null,
    deleteNewsEnglish: null,
    deleteNewsTamil: null,
    editNewsSinhala: false,
    editNewsEnglish: false,
    editNewsTamil: false,
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    socket.on('advertisements-updated', fetchAdvertisements);
    socket.on('news-updated', fetchNews);
    socket.on('news-deleted', id => {
      setNews(prevNews => prevNews.filter(post => post.id !== id));
      setNewsEnglish(prevNews => prevNews.filter(post => post.id !== id));
      setNewsTamil(prevNews => prevNews.filter(post => post.id !== id));
    });

    fetchImages();
    fetchVideos();
    fetchAdvertisements();
    fetchNews();
    fetchAdvertisementsEnglish();
    fetchNewsEnglish();
    fetchAdvertisementsTamil();
    fetchNewsTamil();

    return () => {
      socket.disconnect();
    };
  }, [navigate]);

  const fetchImages = async () => {
    try {
      const response = await axios.get('/api/slider-images');
      setImages(response.data);
    } catch (error) {
      toast.error('Error fetching images');
      console.error('Error fetching images:', error);
    }
  };

  const fetchVideos = async () => {
    try {
      const response = await axios.get('/youtube-videos');
      setVideos(response.data);
    } catch (error) {
      toast.error('Error fetching videos');
      console.error('Error fetching videos:', error);
    }
  };

  const fetchAdvertisements = async () => {
    try {
      const result = await axios.get('/api/advertisements');
      setAdvertisements(result.data);
    } catch (error) {
      toast.error('Error fetching advertisements');
      console.error('Error fetching advertisements:', error);
    }
  };

  const fetchNews = async () => {
    try {
      const result = await axios.get('/api/news?page=1&limit=10');
      setNews(result.data);
    } catch (error) {
      toast.error('Error fetching news');
      console.error('Error fetching news:', error);
    }
  };

  const fetchAdvertisementsEnglish = async () => {
    try {
      const result = await axios.get('/api/english-advertisements');
      setAdvertisementsEnglish(result.data);
    } catch (error) {
      toast.error('Error fetching advertisements');
      console.error('Error fetching advertisements:', error);
    }
  };

  const fetchNewsEnglish = async () => {
    try {
      const result = await axios.get('/api/english-news?page=1&limit=10');
      setNewsEnglish(result.data);
    } catch (error) {
      toast.error('Error fetching news');
      console.error('Error fetching news:', error);
    }
  };

  const fetchAdvertisementsTamil = async () => {
    try {
      const result = await axios.get('/api/tamil-advertisements');
      setAdvertisementsTamil(result.data);
    } catch (error) {
      toast.error('Error fetching advertisements');
      console.error('Error fetching advertisements:', error);
    }
  };

  const fetchNewsTamil = async () => {
    try {
      const result = await axios.get('/api/tamil-news?page=1&limit=10');
      setNewsTamil(result.data);
    } catch (error) {
      toast.error('Error fetching news');
      console.error('Error fetching news:', error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoadingState({ ...loadingState, uploadBanner: true });
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Image uploaded successfully!');
      fileInputRef.current.value = '';
      setFile(null);
      fetchImages();
    } catch (error) {
      toast.error('Error uploading image');
      console.error('Error uploading image:', error);
    } finally {
      setLoadingState({ ...loadingState, uploadBanner: false });
    }
  };

  const handleVideoSubmit = async (e) => {
    e.preventDefault();
    setLoadingState({ ...loadingState, uploadVideo: true });
    try {
      const response = await axios.post('/youtube-videos', {
        title: videoTitle,
        link: videoLink
      });
      toast.success('Video added successfully!');
      setVideoTitle('');
      setVideoLink('');
      fetchVideos();
    } catch (error) {
      toast.error('Error adding video');
      console.error('Error adding video:', error);
    } finally {
      setLoadingState({ ...loadingState, uploadVideo: false });
    }
  };

  const handleDeleteImage = async (id) => {
    setLoadingState({ ...loadingState, deleteImage: id });
    try {
      await axios.delete(`images/${id}`);
      toast.success('Image deleted successfully');
      fetchImages();
    } catch (error) {
      toast.error('Error deleting image');
      console.error('Error deleting image:', error);
    } finally {
      setLoadingState({ ...loadingState, deleteImage: null });
    }
  };

  const handleDeleteVideo = async (id) => {
    setLoadingState({ ...loadingState, deleteVideo: id });
    try {
      await axios.delete(`/youtube-videos/${id}`);
      toast.success('Video deleted successfully');
      fetchVideos();
    } catch (error) {
      toast.error('Error deleting video');
      console.error('Error deleting video:', error);
    } finally {
      setLoadingState({ ...loadingState, deleteVideo: null });
    }
  };

  const handleAdFileChange = (e) => {
    setAdFile(e.target.files[0]);
  };

  const handleAdSubmit = async (e) => {
    e.preventDefault();
    setLoadingState({ ...loadingState, uploadAdvertisement: true });
    const formData = new FormData();
    formData.append('image', adFile);

    try {
      const response = await axios.post('/api/advertisements', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Advertisement uploaded successfully!');
      setAdFile(null);
      fetchAdvertisements();
    } catch (error) {
      toast.error('Error uploading advertisement');
      console.error('Error uploading advertisement:', error);
    } finally {
      setLoadingState({ ...loadingState, uploadAdvertisement: false });
    }
  };

  const handleDeleteAd = async (id) => {
    setLoadingState({ ...loadingState, deleteAd: id });
    try {
      await axios.delete(`/api/advertisements/${id}`);
      toast.success('Advertisement deleted successfully');
      fetchAdvertisements();
    } catch (error) {
      toast.error('Error deleting advertisement');
      console.error('Error deleting advertisement:', error);
    } finally {
      setLoadingState({ ...loadingState, deleteAd: null });
    }
  };

  const handleNewsFileChange = (e) => {
    setNewsFile(e.target.files[0]);
  };

  const handleNewsSubmit = async (e) => {
    e.preventDefault();
    setLoadingState({ ...loadingState, uploadNewsSinhala: true });
    const formData = new FormData();
    formData.append('image', newsFile);
    formData.append('heading', newsHeading);
    formData.append('description', newsDescription);
    formData.append('video_url', newsVideoUrl);

    try {
      const response = await axios.post('/api/news', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('News post uploaded successfully!');
      setNewsFile(null);
      setNewsHeading('');
      setNewsDescription('');
      setNewsVideoUrl('');
      fetchNews();
    } catch (error) {
      toast.error('Error uploading news post');
      console.error('Error uploading news post:', error);
    } finally {
      setLoadingState({ ...loadingState, uploadNewsSinhala: false });
    }
  };

  const handleDeleteNews = async (id) => {
    setLoadingState({ ...loadingState, deleteNewsSinhala: id });
    try {
      await axios.delete(`/api/news/${id}`);
      toast.success('News post deleted successfully');
      fetchNews();
    } catch (error) {
      toast.error('Error deleting news post');
      console.error('Error deleting news post:', error);
    } finally {
      setLoadingState({ ...loadingState, deleteNewsSinhala: null });
    }
  };

  const handleEditNews = (post) => {
    setEditingPostSinhala(post);
    setShowEditModalSinhala(true);
  };

  const handleNewsFileChangeEnglish = (e) => {
    setNewsFileEnglish(e.target.files[0]);
  };

  const handleNewsSubmitEnglish = async (e) => {
    e.preventDefault();
    setLoadingState({ ...loadingState, uploadNewsEnglish: true });
    const formData = new FormData();
    formData.append('image', newsFileEnglish);
    formData.append('heading', newsHeadingEnglish);
    formData.append('description', newsDescriptionEnglish);
    formData.append('video_url', newsVideoUrlEnglish);

    try {
      const response = await axios.post('/api/english-news', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('English news post uploaded successfully!');
      setNewsFileEnglish(null);
      setNewsHeadingEnglish('');
      setNewsDescriptionEnglish('');
      setNewsVideoUrlEnglish('');
      fetchNewsEnglish();
    } catch (error) {
      toast.error('Error uploading English news post');
      console.error('Error uploading English news post:', error);
    } finally {
      setLoadingState({ ...loadingState, uploadNewsEnglish: false });
    }
  };

  const handleDeleteNewsEnglish = async (id) => {
    setLoadingState({ ...loadingState, deleteNewsEnglish: id });
    try {
      await axios.delete(`/api/english-news/${id}`);
      toast.success('English news post deleted successfully');
      fetchNewsEnglish();
    } catch (error) {
      toast.error('Error deleting English news post');
      console.error('Error deleting English news post:', error);
    } finally {
      setLoadingState({ ...loadingState, deleteNewsEnglish: null });
    }
  };

  const handleEditNewsEnglish = (post) => {
    setEditingPostEnglish(post);
    setShowEditModalEnglish(true);
  };

  const handleNewsFileChangeTamil = (e) => {
    setNewsFileTamil(e.target.files[0]);
  };

  const handleNewsSubmitTamil = async (e) => {
    e.preventDefault();
    setLoadingState({ ...loadingState, uploadNewsTamil: true });
    const formData = new FormData();
    formData.append('image', newsFileTamil);
    formData.append('heading', newsHeadingTamil);
    formData.append('description', newsDescriptionTamil);
    formData.append('video_url', newsVideoUrlTamil);

    try {
      const response = await axios.post('/api/tamil-news', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Tamil news post uploaded successfully!');
      setNewsFileTamil(null);
      setNewsHeadingTamil('');
      setNewsDescriptionTamil('');
      setNewsVideoUrlTamil('');
      fetchNewsTamil();
    } catch (error) {
      toast.error('Error uploading Tamil news post');
      console.error('Error uploading Tamil news post:', error);
    } finally {
      setLoadingState({ ...loadingState, uploadNewsTamil: false });
    }
  };

  const handleDeleteNewsTamil = async (id) => {
    setLoadingState({ ...loadingState, deleteNewsTamil: id });
    try {
      await axios.delete(`/api/tamil-news/${id}`);
      toast.success('Tamil news post deleted successfully');
      fetchNewsTamil();
    } catch (error) {
      toast.error('Error deleting Tamil news post');
      console.error('Error deleting Tamil news post:', error);
    } finally {
      setLoadingState({ ...loadingState, deleteNewsTamil: null });
    }
  };

  const handleEditNewsTamil = (post) => {
    setEditingPostTamil(post);
    setShowEditModalTamil(true);
  };

  const cleanFields = () => {
    setNewsFile(null);
    setNewsHeading('');
    setNewsDescription('');
    setNewsVideoUrl('');
    setAdFile(null);
    setNewsFileEnglish(null);
    setNewsHeadingEnglish('');
    setNewsDescriptionEnglish('');
    setNewsVideoUrlEnglish('');
    setAdFileEnglish(null);
    setNewsFileTamil(null);
    setNewsHeadingTamil('');
    setNewsDescriptionTamil('');
    setNewsVideoUrlTamil('');
    setAdFileTamil(null);
  };

  const handleUpdateNews = async () => {
    setLoadingState({ ...loadingState, editNewsSinhala: true });
    const formData = new FormData();
    formData.append('image', editingPostSinhala.imageFile);
    formData.append('heading', editingPostSinhala.heading);
    formData.append('description', editingPostSinhala.description);
    formData.append('video_url', editingPostSinhala.video_url);

    try {
      const response = await axios.put(`/api/news/${editingPostSinhala.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('News post updated successfully!');
      setShowEditModalSinhala(false);
      fetchNews();
    } catch (error) {
      toast.error('Error updating news post');
      console.error('Error updating news post:', error);
    } finally {
      setLoadingState({ ...loadingState, editNewsSinhala: false });
    }
  };

  const handleUpdateNewsEnglish = async () => {
    setLoadingState({ ...loadingState, editNewsEnglish: true });
    const formData = new FormData();
    formData.append('image', editingPostEnglish.imageFile);
    formData.append('heading', editingPostEnglish.heading);
    formData.append('description', editingPostEnglish.description);
    formData.append('video_url', editingPostEnglish.video_url);

    try {
      const response = await axios.put(`/api/english-news/${editingPostEnglish.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('English news post updated successfully!');
      setShowEditModalEnglish(false);
      fetchNewsEnglish();
    } catch (error) {
      toast.error('Error updating English news post');
      console.error('Error updating English news post:', error);
    } finally {
      setLoadingState({ ...loadingState, editNewsEnglish: false });
    }
  };

  const handleUpdateNewsTamil = async () => {
    setLoadingState({ ...loadingState, editNewsTamil: true });
    const formData = new FormData();
    formData.append('image', editingPostTamil.imageFile);
    formData.append('heading', editingPostTamil.heading);
    formData.append('description', editingPostTamil.description);
    formData.append('video_url', editingPostTamil.video_url);

    try {
      const response = await axios.put(`/api/tamil-news/${editingPostTamil.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Tamil news post updated successfully!');
      setShowEditModalTamil(false);
      fetchNewsTamil();
    } catch (error) {
      toast.error('Error updating Tamil news post');
      console.error('Error updating Tamil news post:', error);
    } finally {
      setLoadingState({ ...loadingState, editNewsTamil: false });
    }
  };

  const getEmbedUrl = (link) => {
    const videoId = link.split('v=')[1] || link.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const handleViewNewsSinhala = (post) => {
    setEditingPostSinhala(post);
    setShowViewModalSinhala(true);
  };

  const handleViewNewsEnglish = (post) => {
    setEditingPostEnglish(post);
    setShowViewModalEnglish(true);
  };

  const handleViewNewsTamil = (post) => {
    setEditingPostTamil(post);
    setShowViewModalTamil(true);
  };

  return (
    <>
      <HeaderAdmin defaultMuted={true} />
      <div className="container mt-4">
        <h2 className="text-center mb-4">Hello Radio Dashboard</h2>

        <div className="row">
          <div className="col-md-3">
            <div className="card mb-4">
              <div className="card-header bg-primary text-white">
                <h3 className="card-title">Advertisements</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleUpload}>
                  <h4>Upload Banner</h4>
                  <input type="file" onChange={handleFileChange} ref={fileInputRef} className="form-control mb-2" />
                  <button type="submit" className="btn btn-primary">
                    {loadingState.uploadBanner ? <Spinner as="span" animation="border" size="sm" /> : 'Upload Image'}
                  </button>
                </form>

                <form onSubmit={handleVideoSubmit} className="mt-4">
                  <h4>Upload Video Link</h4>
                  <input
                    type="text"
                    placeholder="Video Title"
                    value={videoTitle}
                    onChange={(e) => setVideoTitle(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input
                    type="text"
                    placeholder="YouTube Video Link"
                    value={videoLink}
                    onChange={(e) => setVideoLink(e.target.value)}
                    className="form-control mb-2"
                  />
                  <button type="submit" className="btn btn-primary">
                    {loadingState.uploadVideo ? <Spinner as="span" animation="border" size="sm" /> : 'Add Video'}
                  </button>
                </form>

                <form onSubmit={handleAdSubmit} className="mt-4">
                  <h4>Upload Advertisement</h4>
                  <input type="file" onChange={handleAdFileChange} className="form-control mb-2" />
                  <button type="submit" className="btn btn-primary" disabled={advertisements.length >= 2}>
                    {loadingState.uploadAdvertisement ? <Spinner as="span" animation="border" size="sm" /> : 'Upload Advertisement'}
                  </button>
                </form>

                <h4 className="mt-4">Ongoing Banners</h4>
                <ul className="list-unstyled">
                  {images.map((image) => (
                    <li key={image.id} className="mb-2">
                      <img src={`http://localhost:5000${image.image_url}`} alt={`Slide ${image.id}`} width="100" />
                      <button onClick={() => handleDeleteImage(image.id)} className="btn btn-danger btn-sm ml-2">
                        {loadingState.deleteImage === image.id ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
                      </button>
                    </li>
                  ))}
                </ul>

                <h4 className="mt-4">Ongoing Advertisements</h4>
                <div className="d-flex flex-wrap">
                  {advertisements.map(ad => (
                    <div key={ad.id} className="mb-2 mr-2">
                      <img src={ad.image_url} alt="Advertisement" width="100" height="100" />
                      <button onClick={() => handleDeleteAd(ad.id)} className="btn btn-danger btn-sm mt-2">
                        {loadingState.deleteAd === ad.id ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
                      </button>
                    </div>
                  ))}
                </div>

                <h4 className="mt-4">Uploaded Videos Links</h4>
                <ul className="list-unstyled">
                  {videos.map((video) => (
                    <li key={video.id} className="mb-2">
                      <iframe
                        width="100%"
                        height="200"
                        src={getEmbedUrl(video.link)}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      <div>{video.title}</div>
                      <button onClick={() => handleDeleteVideo(video.id)} className="btn btn-danger btn-sm mt-2">
                        {loadingState.deleteVideo === video.id ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="card-footer text-muted">
                Manage your advertisements here.
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card mb-4">
              <div className="card-header bg-success text-white">
                <h3 className="card-title">Sinhala News</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleNewsSubmit}>
                  <h4>Upload News Post</h4>
                  <input
                    type="text"
                    placeholder="Heading"
                    value={newsHeading}
                    onChange={(e) => setNewsHeading(e.target.value)}
                    className="form-control mb-2"
                  />
                  <textarea
                    placeholder="Description"
                    value={newsDescription}
                    onChange={(e) => setNewsDescription(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input
                    type="text"
                    placeholder="Video URL"
                    value={newsVideoUrl}
                    onChange={(e) => setNewsVideoUrl(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input type="file" onChange={handleNewsFileChange} className="form-control mb-2" />
                  <button type="submit" className="btn btn-primary">
                    {loadingState.uploadNewsSinhala ? <Spinner as="span" animation="border" size="sm" /> : 'Upload News'}
                  </button>
                </form>

                <h4 className="mt-4">Last {isExtended ? '10' : '4'} News Posts</h4>
                <div className="d-flex flex-wrap">
                  {news.slice(0, isExtended ? 10 : 4).map(post => (
                    <div key={post.id} className="card mb-2" style={{ width: '200px' }}>
                      <img src={post.image_url} className="card-img-top" alt="News" />
                      <div className="card-body">
                        <h5 className="card-title">{post.heading}</h5>
                        <p className="card-text">{post.description.split(' ').slice(0, 6).join(' ')}...</p>
                        <button onClick={() => handleEditNews(post)} className="btn btn-primary btn-sm mr-2">Edit</button>
                        <button onClick={() => handleDeleteNews(post.id)} className="btn btn-danger btn-sm">
                          {loadingState.deleteNewsSinhala === post.id ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
                        </button>
                        <button onClick={() => handleViewNewsSinhala(post)} className="btn btn-secondary btn-sm ml-2">View</button>
                      </div>
                    </div>
                  ))}
                </div>
                <button onClick={() => setIsExtended(!isExtended)} className="btn btn-secondary mt-2">
                  {isExtended ? 'Collapse' : 'Extend'}
                </button>
              </div>
              <div className="card-footer text-muted">
                Manage Sinhala news here.
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card mb-4">
              <div className="card-header bg-info text-white">
                <h3 className="card-title">English News</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleNewsSubmitEnglish}>
                  <h4>Upload News Post</h4>
                  <input
                    type="text"
                    placeholder="Heading"
                    value={newsHeadingEnglish}
                    onChange={(e) => setNewsHeadingEnglish(e.target.value)}
                    className="form-control mb-2"
                  />
                  <textarea
                    placeholder="Description"
                    value={newsDescriptionEnglish}
                    onChange={(e) => setNewsDescriptionEnglish(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input
                    type="text"
                    placeholder="Video URL"
                    value={newsVideoUrlEnglish}
                    onChange={(e) => setNewsVideoUrlEnglish(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input type="file" onChange={handleNewsFileChangeEnglish} className="form-control mb-2" />
                  <button type="submit" className="btn btn-primary">
                    {loadingState.uploadNewsEnglish ? <Spinner as="span" animation="border" size="sm" /> : 'Upload News'}
                  </button>
                </form>

                <h4 className="mt-4">Last {isExtendedEnglish ? '10' : '4'} News Posts</h4>
                <div className="d-flex flex-wrap">
                  {newsEnglish.slice(0, isExtendedEnglish ? 10 : 4).map(post => (
                    <div key={post.id} className="card mb-2" style={{ width: '200px' }}>
                      <img src={post.image_url} className="card-img-top" alt="News" />
                      <div className="card-body">
                        <h5 className="card-title">{post.heading}</h5>
                        <p className="card-text">{post.description.split(' ').slice(0, 6).join(' ')}...</p>
                        <button onClick={() => handleEditNewsEnglish(post)} className="btn btn-primary btn-sm mr-2">Edit</button>
                        <button onClick={() => handleDeleteNewsEnglish(post.id)} className="btn btn-danger btn-sm">
                          {loadingState.deleteNewsEnglish === post.id ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
                        </button>
                        <button onClick={() => handleViewNewsEnglish(post)} className="btn btn-secondary btn-sm ml-2">View</button>
                      </div>
                    </div>
                  ))}
                </div>
                <button onClick={() => setIsExtendedEnglish(!isExtendedEnglish)} className="btn btn-secondary mt-2">
                  {isExtendedEnglish ? 'Collapse' : 'Extend'}
                </button>
              </div>
              <div className="card-footer text-muted">
                Manage English news here.
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="card mb-4">
              <div className="card-header bg-warning text-white">
                <h3 className="card-title">Tamil News</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleNewsSubmitTamil}>
                  <h4>Upload News Post</h4>
                  <input
                    type="text"
                    placeholder="Heading"
                    value={newsHeadingTamil}
                    onChange={(e) => setNewsHeadingTamil(e.target.value)}
                    className="form-control mb-2"
                  />
                  <textarea
                    placeholder="Description"
                    value={newsDescriptionTamil}
                    onChange={(e) => setNewsDescriptionTamil(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input
                    type="text"
                    placeholder="Video URL"
                    value={newsVideoUrlTamil}
                    onChange={(e) => setNewsVideoUrlTamil(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input type="file" onChange={handleNewsFileChangeTamil} className="form-control mb-2" />
                  <button type="submit" className="btn btn-primary">
                    {loadingState.uploadNewsTamil ? <Spinner as="span" animation="border" size="sm" /> : 'Upload News'}
                  </button>
                </form>

                <h4 className="mt-4">Last {isExtendedTamil ? '10' : '4'} News Posts</h4>
                <div className="d-flex flex-wrap">
                  {newsTamil.slice(0, isExtendedTamil ? 10 : 4).map(post => (
                    <div key={post.id} className="card mb-2" style={{ width: '200px' }}>
                      <img src={post.image_url} className="card-img-top" alt="News" />
                      <div className="card-body">
                        <h5 className="card-title">{post.heading}</h5>
                        <p className="card-text">{post.description.split(' ').slice(0, 6).join(' ')}...</p>
                        <button onClick={() => handleEditNewsTamil(post)} className="btn btn-primary btn-sm mr-2">Edit</button>
                        <button onClick={() => handleDeleteNewsTamil(post.id)} className="btn btn-danger btn-sm">
                          {loadingState.deleteNewsTamil === post.id ? <Spinner as="span" animation="border" size="sm" /> : 'Delete'}
                        </button>
                        <button onClick={() => handleViewNewsTamil(post)} className="btn btn-secondary btn-sm ml-2">View</button>
                      </div>
                    </div>
                  ))}
                </div>
                <button onClick={() => setIsExtendedTamil(!isExtendedTamil)} className="btn btn-secondary mt-2">
                  {isExtendedTamil ? 'Collapse' : 'Extend'}
                </button>
              </div>
              <div className="card-footer text-muted">
                Manage Tamil news here.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />

      <Modal show={showEditModalSinhala} onHide={() => setShowEditModalSinhala(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Sinhala News Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="editHeading" className="form-label">Heading</label>
              <input
                type="text"
                className="form-control"
                id="editHeading"
                value={editingPostSinhala?.heading || ''}
                onChange={(e) => setEditingPostSinhala({ ...editingPostSinhala, heading: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="editDescription" className="form-label">Description</label>
              <textarea
                className="form-control"
                id="editDescription"
                rows="3"
                value={editingPostSinhala?.description || ''}
                onChange={(e) => setEditingPostSinhala({ ...editingPostSinhala, description: e.target.value })}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="editVideoUrl" className="form-label">Video URL</label>
              <input
                type="text"
                className="form-control"
                id="editVideoUrl"
                value={editingPostSinhala?.video_url || ''}
                onChange={(e) => setEditingPostSinhala({ ...editingPostSinhala, video_url: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="editImage" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id="editImage"
                onChange={(e) => setEditingPostSinhala({ ...editingPostSinhala, imageFile: e.target.files[0] })}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModalSinhala(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateNews}>
            {loadingState.editNewsSinhala ? <Spinner as="span" animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModalEnglish} onHide={() => setShowEditModalEnglish(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit English News Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="editHeadingEnglish" className="form-label">Heading</label>
              <input
                type="text"
                className="form-control"
                id="editHeadingEnglish"
                value={editingPostEnglish?.heading || ''}
                onChange={(e) => setEditingPostEnglish({ ...editingPostEnglish, heading: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="editDescriptionEnglish" className="form-label">Description</label>
              <textarea
                className="form-control"
                id="editDescriptionEnglish"
                rows="3"
                value={editingPostEnglish?.description || ''}
                onChange={(e) => setEditingPostEnglish({ ...editingPostEnglish, description: e.target.value })}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="editVideoUrlEnglish" className="form-label">Video URL</label>
              <input
                type="text"
                className="form-control"
                id="editVideoUrlEnglish"
                value={editingPostEnglish?.video_url || ''}
                onChange={(e) => setEditingPostEnglish({ ...editingPostEnglish, video_url: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="editImageEnglish" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id="editImageEnglish"
                onChange={(e) => setEditingPostEnglish({ ...editingPostEnglish, imageFile: e.target.files[0] })}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModalEnglish(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateNewsEnglish}>
            {loadingState.editNewsEnglish ? <Spinner as="span" animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModalTamil} onHide={() => setShowEditModalTamil(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tamil News Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="editHeadingTamil" className="form-label">Heading</label>
              <input
                type="text"
                className="form-control"
                id="editHeadingTamil"
                value={editingPostTamil?.heading || ''}
                onChange={(e) => setEditingPostTamil({ ...editingPostTamil, heading: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="editDescriptionTamil" className="form-label">Description</label>
              <textarea
                className="form-control"
                id="editDescriptionTamil"
                rows="3"
                value={editingPostTamil?.description || ''}
                onChange={(e) => setEditingPostTamil({ ...editingPostTamil, description: e.target.value })}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="editVideoUrlTamil" className="form-label">Video URL</label>
              <input
                type="text"
                className="form-control"
                id="editVideoUrlTamil"
                value={editingPostTamil?.video_url || ''}
                onChange={(e) => setEditingPostTamil({ ...editingPostTamil, video_url: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="editImageTamil" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id="editImageTamil"
                onChange={(e) => setEditingPostTamil({ ...editingPostTamil, imageFile: e.target.files[0] })}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModalTamil(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateNewsTamil}>
            {loadingState.editNewsTamil ? <Spinner as="span" animation="border" size="sm" /> : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showViewModalSinhala} onHide={() => setShowViewModalSinhala(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View Sinhala News Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{editingPostSinhala?.heading}</h3>
          <img src={editingPostSinhala?.image_url} className="img-fluid" alt="News" />
          <p>{editingPostSinhala?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowViewModalSinhala(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showViewModalEnglish} onHide={() => setShowViewModalEnglish(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View English News Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{editingPostEnglish?.heading}</h3>
          <img src={editingPostEnglish?.image_url} className="img-fluid" alt="News" />
          <p>{editingPostEnglish?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowViewModalEnglish(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showViewModalTamil} onHide={() => setShowViewModalTamil(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View Tamil News Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{editingPostTamil?.heading}</h3>
          <img src={editingPostTamil?.image_url} className="img-fluid" alt="News" />
          <p>{editingPostTamil?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowViewModalTamil(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminDashboard;
