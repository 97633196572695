import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="logo-container">
          <img src="/Logo.png" alt="Hello Radio Logo" className="logo" />
        </div>
        <div className="social-media">
          <span>Social Media: </span>
          <a href="https://web.facebook.com/helloradio.lk" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.youtube.com/@helloradio" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://www.instagram.com/helloradio_lk/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.tiktok.com/@helloradio.lk" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        </div>
      </div>
      <div className="footer-middle">
        <p className="description">
          Hello Radio.LK is an Online Radio service for all entertainment lovers around the world.
        </p>
        <div className="contact-info">
          <p>Contact Number: 076 141 0999</p>
          <p>Email: <a href="mailto:info@helloradio.lk">info@helloradio.lk</a></p>
          <p>Address: 17B, Sthagama, Avissawella, Avissawella, Sri Lanka</p>
        </div>
        <div className="partners">
          <p>Partnership with</p>
          <a href="https://www.in4gen.com" target="_blank" rel="noopener noreferrer">
            <img src="/in4genlogo.png" alt="In4gen Solution Logo" className="partner-logo" />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 <a href="https://www.in4gen.com" target="_blank" rel="noopener noreferrer" className="in4gen-link">In4gen Solution</a>. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
