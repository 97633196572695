import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: white;
    color: black; /* White text color */
      text-align: justify;

  }
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 20px; /* Add top padding to offset the fixed header */
  max-width: 800px;
  margin: 0 auto;
  

`;

const ImgStyle = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const IframeStyle = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  margin-bottom: 15px;
`;

const PostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await axios.get(`/api/news/${id}`);
        setPost(result.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [id]);

  const getYoutubeEmbedUrl = (url) => {
    if (!url) return '';
    let videoId = '';
    if (url.includes('youtu.be')) {
      videoId = url.split('/').pop();
    } else if (url.includes('youtube.com')) {
      const params = new URLSearchParams(url.split('?')[1]);
      videoId = params.get('v');
    }
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div>
      <Header />
      <GlobalStyle />
      <PostContainer>
        {post ? (
          <>
            <h1>{post.heading}</h1>
            <ImgStyle src={post.image_url} alt={post.heading} />
            {post.video_url && (
              <IframeStyle
                src={getYoutubeEmbedUrl(post.video_url)}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Video"
              />
            )}
            <p>{post.description}</p>
            <p><small>{new Date(post.posted_at).toLocaleString()}</small></p>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </PostContainer>
      <Footer />
    </div>
  );
};

export default PostPage;
