import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SectionAds = ({ section }) => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get(`https://helloradio.lk/api/section-ads?section=${section}`);
        setAds(response.data);
      } catch (error) {
        console.error('Error fetching section ads:', error);
      }
    };

    fetchAds();
  }, [section]);

  return (
    <div>
      {ads.map((ad) => (
        <img key={ad.id} src={`https://helloradio.lk${ad.image_url}`} alt="Advertisement" style={{ width: '100%', marginBottom: '10px' }} />
      ))}
    </div>
  );
};

export default SectionAds;
