import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import styled from 'styled-components';

const socket = io('https://helloradio.lk/');
// const socket = io('http://localhost:5000/');

const VideoContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const LargeVideo = styled.iframe`
  width: 100%;
  height: 300px; /* Increase height for the first video */
`;

const SmallVideo = styled.iframe`
  width: 100%;
  height: 220px; /* Keep the same height for the rest */
`;

const SectionHeading = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  color: #d32f2f;
  padding: 10px;
  border-bottom: 2px solid #d32f2f;
  font-weight: bold;
`;

const VideoTitleContainer = styled.div`
  text-align: center;
  margin-top: -8px;
  padding: 10px;
  background-color: red;
  color: white; /* Optional: to ensure text is readable against the red background */
    font-weight: bold;
`;

const YoutubeVideos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get('/youtube-videos');
        if (Array.isArray(response.data)) {
          setVideos(response.data);
        } else {
          console.error('API response is not an array:', response.data);
          setVideos([]);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
        setVideos([]);
      }
    };

    fetchVideos();

    socket.on('newVideo', (newVideo) => {
      setVideos((prevVideos) => [newVideo, ...prevVideos]);
    });

    socket.on('deleteVideo', (videoId) => {
      setVideos((prevVideos) => prevVideos.filter(video => video.id !== videoId));
    });

    return () => {
      socket.off('newVideo');
      socket.off('deleteVideo');
    };
  }, []);

  const getEmbedUrl = (link) => {
    const videoId = link.split('v=')[1] || link.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div>

      <SectionHeading> </SectionHeading>
      <div>
        {videos.map((video, index) => (
          <VideoContainer key={video.id}>
            {index === 0 ? (
              <LargeVideo
                src={getEmbedUrl(video.link)}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></LargeVideo>
            ) : (
              <SmallVideo
                src={getEmbedUrl(video.link)}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></SmallVideo>
            )}
           <VideoTitleContainer> <div style={{ textAlign: 'center', marginTop: '10px' }}>{video.title}</div></VideoTitleContainer>
          </VideoContainer>
        ))}
      </div>
    </div>
  );
};

export default YoutubeVideos;
