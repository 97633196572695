import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';
import styled, { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import notificationSound from './notification-sound.mp3';
import watermark from './watermark.png';
import RadioPlayer from './RadioPlayer';
import Header from './Header';
import Footer from './Footer';
import Slider from './Slider';
import YoutubeVideos from './YoutubeVideos';
import SectionAds from './SectionAds';
import './Header.css';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #f5f5f5;
    color: #333;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
`;

const MainBanner = styled.section`
  position: relative;
  background: url('./banner.jpg') no-repeat center center/cover;
  color: #fff;
  text-align: center;
  padding: 60px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 40px 10px;
  }
`;

const SectionHeading = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  color: #d32f2f;
  padding: 10px;
  border-bottom: 2px solid #d32f2f;
  font-weight: bold;
`;

const SidebarContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const NewsContainer = styled.div`
  flex: 3;
  padding: 20px;
  box-sizing: border-box;
`;

const TopNewsContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: -70px; /* Adjust the top margin for smaller screens */
  }
`;

const TopNewsImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const TopNewsTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: bold;
`;

const TopNewsDescription = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: justify;
`;

const NewsItem = styled.div`
  display: block;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  width: 100%;
`;

const NewsImage = styled.img`
  width: 120px;
  height: 80px;
  margin: 0 15px 10px 0;
  float: left;
  border-radius: 10px;
  cursor: pointer;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const NewsContent = styled.div`
  display: block;
`;

const NewsTitle = styled.h5`
  margin: 0;
  font-size: 16px;
  color: #d32f2f;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

const NewsDescription = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #333;
  text-align: justify;
`;

const NewsMeta = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: #666;
  clear: both;
`;

const ImageGallery = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 91px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const GalleryImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
`;

const ReadMore = styled.span`
  display: inline;
  color: blue;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  animation: shake 0.5s infinite;
`;

const Heading = styled.h1`
  margin: 10px 0 0;
  color: red;
  font-size: 50px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 22px; /* Smaller font size on smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 20px; /* Even smaller font size on very small screens */
  }
`;

const Text = styled.p`
  margin: 10px 0 0;
  color: red;
  font-size: 25px;

  @media (max-width: 768px) {
    font-size: 16px; /* Smaller font size on smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 14px; /* Even smaller font size on very small screens */
  }

  @media (max-width: 600px) {
    margin-bottom: 5px; /* Adjust the bottom margin for smaller screens */
  }
`;

const VideoAvailableTextStyle = styled.span`
  display: inline-block;
  color: white;
  background-color: red;
  font-size:10px;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const Heading1 = styled.h4`
  margin: 10px 0 0;
  color: red;
  font-size: 18px;
`;

const EnglishHomepage = () => {
  const [ads, setAds] = useState([]);
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [expandedPosts, setExpandedPosts] = useState({});
  const [isTopNewsDescriptionExpanded, setIsTopNewsDescriptionExpanded] = useState(false);
  const audioRef = useRef(null);
  const lastFetchedPostIdRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const result = await axios.get('/api/advertisements');
        setAds(result.data);
      } catch (error) {
        console.error('Error fetching advertisements:', error);
      }
    };

    fetchAdvertisements();

    const socket = io('https://helloradio.lk');

    socket.on('advertisements-updated', fetchAdvertisements);

    return () => {
      socket.off('advertisements-updated', fetchAdvertisements);
    };
  }, []);

  useEffect(() => {
    fetchNews(1);

    const socket = io('https://helloradio.lk');

    socket.on('news-updated', fetchLatestNews);

    return () => {
      socket.off('news-updated', fetchLatestNews);
    };
  }, []);

  const fetchNews = async (page) => {
    try {
      const result = await axios.get(`/api/english-news?page=${page}&limit=15`);
      console.log('News data:', result.data);
      if (result.data.length > 0) {
        setNews(prevNews => page === 1 ? result.data : [...prevNews, ...result.data]);
        if (page === 1) {
          lastFetchedPostIdRef.current = result.data[0]?.id;
        }
      }
      if (result.data.length < 15) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  const fetchLatestNews = async () => {
    try {
      const result = await axios.get(`/api/english-news?page=1&limit=1`);
      console.log('Latest news data:', result.data);
      if (result.data.length > 0 && result.data[0].id !== lastFetchedPostIdRef.current) {
        setNews(prevNews => [result.data[0], ...prevNews]);
        playNotificationSound();
        lastFetchedPostIdRef.current = result.data[0].id;
      }
    } catch (error) {
      console.error('Error fetching latest news:', error);
    }
  };

  const playNotificationSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.error('Error playing notification sound:', error);
      });
    }
  };

  const handlePostClick = (post) => {
    navigate(`/postenglish/${post.id}`);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const openShareModal = () => {
    setShareModalVisible(true);
  };

  const closeShareModal = () => {
    setShareModalVisible(false);
    setCopied(false);
  };

  const getYoutubeEmbedUrl = (url) => {
    if (!url) return '';
    let videoId = '';
    if (url.includes('youtu.be')) {
      videoId = url.split('/').pop();
    } else if (url.includes('youtube.com')) {
      const params = new URLSearchParams(url.split('?')[1]);
      videoId = params.get('v');
    }
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    console.log('Embed URL:', embedUrl);
    return embedUrl;
  };

  const handleShowMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchNews(nextPage);
  };

  const handleReadMore = (postId) => {
    setExpandedPosts(prevState => ({
      ...prevState,
      [postId]: !prevState[postId]
    }));
  };

  const handleLogoClick = () => {
    window.open('/radio', '_blank');
  };

  const getShareableLink = () => {
    return selectedPost ? `${window.location.origin}/post/${selectedPost.id}` : '';
  };

  const toggleTopNewsDescription = () => {
    setIsTopNewsDescriptionExpanded(!isTopNewsDescriptionExpanded);
  };

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(' ');
    return words.length > wordLimit
      ? `${words.slice(0, wordLimit).join(' ')}...`
      : description;
  };

  const [leftAd, rightAd, middleAd, fourthAd] = ads.slice(0, 4);

  return (
    <div>
      <Header />
      <GlobalStyle />
      <MainContainer>
        <MainBanner>
          <Slider />
          <SidebarContainer />
          <Logo src="./Live.png" alt="Logo" className="logo" onClick={handleLogoClick} />
          <Heading>HELLO</Heading>
          <Heading1>English News</Heading1>
          <Text>ලෝකෙම අහන ලංකාවේ රේඩියෝ එක</Text>
        </MainBanner>
        <SidebarContainer>
          <NewsContainer>
            {news.length > 0 && (
              <TopNewsContainer>
                <SectionHeading>Top News</SectionHeading>
                <TopNewsImage
                  src={news[0].image_url}
                  alt={news[0].heading}
                  onClick={() => handlePostClick(news[0])}
                />
                <TopNewsTitle>{news[0].heading}</TopNewsTitle>
                <TopNewsDescription>
                  {isTopNewsDescriptionExpanded
                    ? news[0].description
                    : truncateDescription(news[0].description, 80)}
                  <ReadMore onClick={toggleTopNewsDescription}>
                    {isTopNewsDescriptionExpanded ? <FaArrowUp /> : <FaArrowDown />}
                  </ReadMore>
                  {news[0].video_url && (
                    <VideoAvailableTextStyle onClick={(e) => { e.stopPropagation(); window.open(news[0].video_url, '_blank'); }}>
                      Video Available
                    </VideoAvailableTextStyle>
                  )}
                </TopNewsDescription>
              </TopNewsContainer>
            )}
            <SectionHeading>News</SectionHeading>
            {news.slice(1, 5).map((post) => (
              <NewsItem key={post.id} onClick={() => handlePostClick(post)}>
                <NewsImage
                  src={post.image_url}
                  alt={post.heading}
                  visible={true}
                />
                <NewsContent>
                  <NewsTitle>{post.heading}</NewsTitle>
                  <NewsDescription>
                    {expandedPosts[post.id]
                      ? post.description
                      : truncateDescription(post.description, 50)}
                    <ReadMore onClick={(e) => { e.stopPropagation(); handleReadMore(post.id); }}>
                      {expandedPosts[post.id] ? <FaArrowUp /> : <FaArrowDown />}
                    </ReadMore>
                    {post.video_url && (
                      <VideoAvailableTextStyle
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(post.video_url, '_blank');
                        }}
                      >
                        Video Available
                      </VideoAvailableTextStyle>
                    )}
                  </NewsDescription>
                  <NewsMeta>Post a comment | {new Date(post.posted_at).toLocaleString()}</NewsMeta>
                </NewsContent>
              </NewsItem>
            ))}
            <SectionAds section="news" />
            {hasMore && (
              <button onClick={handleShowMore}>Show More</button>
            )}
          </NewsContainer>
          <ImageGallery>
            {ads.slice(0, 5).map((ad, index) => (
              <GalleryImage key={index} src={ad.image_url} alt={`Gallery Image ${index + 1}`} />
            ))}
            <SectionAds section="youtube" />
            <YoutubeVideos />
          </ImageGallery>
        </SidebarContainer>
      </MainContainer>
      <Footer />
    </div>
  );
};

export default EnglishHomepage;
