import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { io } from 'socket.io-client';

const AdvertisementsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
`;

const Advertisement = styled.div`
  margin: 10px;
  img {
    width: 100%;
    height: auto;
    max-width: 300px;
    border-radius: 10px;
  }
`;

const Advertisements = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const result = await axios.get('/api/advertisements');
        setAds(result.data);
      } catch (error) {
        console.error('Error fetching advertisements:', error);
      }
    };

    fetchAdvertisements();

    const socket = io();

    socket.on('advertisements-updated', fetchAdvertisements);

    return () => {
      socket.off('advertisements-updated', fetchAdvertisements);
    };
  }, []);

  return (
    <AdvertisementsContainer>
      {ads.map(ad => (
        <Advertisement key={ad.id}>
          <img src={ad.image_url} alt={`Advertisement ${ad.id}`} />
        </Advertisement>
      ))}
    </AdvertisementsContainer>
  );
};

export default Advertisements;
