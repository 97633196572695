// LoginPage.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* Black background */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Maximum width for the form */
  padding: 20px;
`;

const Logo = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    width: 100px;
    height: 100px;
  }
`;

const Form = styled.form`
  background-color: #1e1e1e; /* Dark background for form */
  padding: 40px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  width: 100%; /* Full width */
  background-color: #000; /* Black input background */
  color: #fff; /* White text */
  border: 1px solid #ff0000; /* Red border */
  border-radius: 5px;

  &::placeholder {
    color: #fff; /* White placeholder text */
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #ff0000; /* Red button background */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%; /* Full width */

  &:hover {
    background-color: #cc0000; /* Darker red on hover */
  }
`;

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { username, password });
      localStorage.setItem('token', response.data.token);
      navigate('/admin');
    } catch (error) {
      alert('Invalid credentials');
    }
  };

  return (
    <div>
    
    <Container>
      <Content>
        <Logo src="/Logo.png" alt="Logo" />
        <Form onSubmit={handleSubmit}>
          <h2>Admin Login</h2>
          <Input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="submit">Login</Button>
        </Form>
      </Content>
    </Container>
    <Footer></Footer>
    </div>
    
  );
};

export default LoginPage;
