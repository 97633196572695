import React, { useEffect, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import './slider.css';

const socket = io('https://helloradio.lk/');
// const socket = io('http://localhost:5000/');

const Slider = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('/api/slider-images');
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
        setImages([]);
      }
    };

    fetchImages();

    socket.on('newImage', (newImage) => {
      setImages((prevImages) => [newImage, ...prevImages]);
    });

    socket.on('deleteImage', (imageId) => {
      setImages((prevImages) => prevImages.filter(image => image.id !== imageId));
    });

    return () => {
      socket.off('newImage');
      socket.off('deleteImage');
    };
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [images]);

  return (
    <div className="slider">
      <div
        className="slider-inner"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {Array.isArray(images) && images.map((image) => (
          <div key={image.id} className="slide">
            <img src={`https://helloradio.lk${image.image_url}`} alt={`Slide ${image.id}`} />
            {/* <img src={`https://localhost:5000${image.image_url}`} alt={`Slide ${image.id}`} /> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
