import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const HeaderAdmin = ({ onBack, onLogout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    if (onLogout) {
      onLogout();
    }
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
    navigate('/home');
  };

  const navigateTo = (path) => {
    window.open(path, '_blank', 'noopener,noreferrer');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-6 col-md-4">
            <img src="/Logo.png" alt="Logo" className="logo" />
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center order-2 order-md-2 mt-2 mt-md-0">
            {/* <RadioPlayer /> */}
          </div>
          <div className="col-6 col-md-4 d-flex justify-content-end order-1 order-md-3 mt-2 mt-md-0">
            <div className="social-media">
              <span></span>
              <a href="https://web.facebook.com/helloradio.lk" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com/helloradio_lk/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.tiktok.com/@helloradio.lk" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-tiktok"></i>
              </a>
              <a href="https://www.youtube.com/@helloradio" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div className="navigation-buttons">
              <div className="dropdown">
                
                {dropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={() => navigateTo('/')} className="dropdown-item">Sinhala</button>
                    <button onClick={() => navigateTo('/english')} className="dropdown-item">English</button>
                    <button onClick={() => navigateTo('/tamil')} className="dropdown-item">Tamil</button>
                  </div>
                )}
              </div>
              <button onClick={handleLogout} className="nav-button logout-button">
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderAdmin;
