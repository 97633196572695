import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

// const socket = io('http://localhost:5000');
const socket = io('https://helloradio.lk');

const Admin = () => {
  const [file, setFile] = useState(null);
  const [images, setImages] = useState([]);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [videos, setVideos] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('/images');
        // Check if the response is an array
        if (Array.isArray(response.data)) {
          setImages(response.data);
        } else {
          console.error('API response is not an array:', response.data);
          setImages([]);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
        setImages([]);
      }
    };

    const fetchVideos = async () => {
      try {
        const response = await axios.get('/youtube-videos');
        // Check if the response is an array
        if (Array.isArray(response.data)) {
          setVideos(response.data);
        } else {
          console.error('API response is not an array:', response.data);
          setVideos([]);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
        setVideos([]);
      }
    };

    fetchImages();
    fetchVideos();

    socket.on('newImage', (newImage) => {
      setImages((prevImages) => [newImage, ...prevImages]);
    });

    socket.on('deleteImage', (imageId) => {
      setImages((prevImages) => prevImages.filter(image => image.id !== imageId));
    });

    socket.on('newVideo', (newVideo) => {
      setVideos((prevVideos) => [newVideo, ...prevVideos]);
    });

    socket.on('deleteVideo', (videoId) => {
      setVideos((prevVideos) => prevVideos.filter(video => video.id !== videoId));
    });

    return () => {
      socket.off('newImage');
      socket.off('deleteImage');
      socket.off('newVideo');
      socket.off('deleteVideo');
    };
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post('https://helloradio.lk/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Image uploaded successfully:', response.data);
      // Clear the file input
      fileInputRef.current.value = '';
      setFile(null);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleVideoSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://helloradio.lk/youtube-videos', {
        title: videoTitle,
        link: videoLink
      });
      console.log('Video added successfully:', response.data);
      setVideoTitle('');
      setVideoLink('');
      // Emit the newVideo event for real-time update
      socket.emit('newVideo', response.data);
    } catch (error) {
      console.error('Error adding video:', error);
    }
  };

  const handleDeleteImage = async (id) => {
    // Optimistically remove the image from the UI
    setImages((prevImages) => prevImages.filter(image => image.id !== id));

    try {
      await axios.delete(`https://helloradio.lk/images/${id}`);
      console.log('Image deleted successfully');
      // Emit deleteImage event to update slider in real-time
      socket.emit('deleteImage', id);
    } catch (error) {
      console.error('Error deleting image:', error);
      // Revert the optimistic update if the deletion fails
      const response = await axios.get('https://helloradio.lk/images');
      setImages(response.data);
    }
  };

  const handleDeleteVideo = async (id) => {
    // Optimistically remove the video from the UI
    setVideos((prevVideos) => prevVideos.filter(video => video.id !== id));

    try {
      await axios.delete(`https://helloradio.lk/youtube-videos/${id}`);
      console.log('Video deleted successfully');
      // Emit deleteVideo event to update videos in real-time
      socket.emit('deleteVideo', id);
    } catch (error) {
      console.error('Error deleting video:', error);
      // Revert the optimistic update if the deletion fails
      const response = await axios.get('https://helloradio.lk/youtube-videos');
      setVideos(response.data);
    }
  };

  const getEmbedUrl = (link) => {
    const videoId = link.split('v=')[1] || link.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div>
      <h2>Admin Page</h2>
      <form onSubmit={handleUpload}>
        <input type="file" onChange={handleFileChange} ref={fileInputRef} />
        <button type="submit">Upload Image</button>
      </form>
      <form onSubmit={handleVideoSubmit}>
        <input
          type="text"
          placeholder="Video Title"
          value={videoTitle}
          onChange={(e) => setVideoTitle(e.target.value)}
        />
        <input
          type="text"
          placeholder="YouTube Video Link (e.g., dQw4w9WgXcQ)"
          value={videoLink}
          onChange={(e) => setVideoLink(e.target.value)}
        />
        <button type="submit">Add Video</button>
      </form>
      <div>
        <h3>Uploaded Images</h3>
        <ul>
          {images.map((image) => (
            <li key={image.id}>
              <img src={`https://helloradio.lk${image.image_url}`} alt={`Slide ${image.id}`} width="100" />
              <button onClick={() => handleDeleteImage(image.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Uploaded Videos</h3>
        <ul>
          {videos.map((video) => (
            <li key={video.id}>
              <iframe
                width="300"
                height="169"
                src={getEmbedUrl(video.link)}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <div>{video.title}</div>
              <button onClick={() => handleDeleteVideo(video.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Admin;
